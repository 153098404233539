<script setup>
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { validateEmail, validateName } from "@/utils/validation";
import axios from "axios";
import { useToast } from "vue-toastification";

const router = useRouter();
const $t = useI18n().t;
const emit = defineEmits(["closeDialog"]);

const subjectTypes = [
  "Account and Billing",
  "Document Support",
  "Legal and Privacy",
  "Technical Assistance",
  "Feedback",
  "Other",
];

const { name, email, withTitle } = defineProps({
  name: {
    type: String,
    default: "",
  },
  email: {
    type: String,
    default: "",
  },
  withTitle: {
    type: Boolean,
    default: true,
  },
});

const formData = reactive({
  name: name,
  email: email,
  subject: "",
  message: "",
});

const errors = reactive({
  name: "",
  email: "",
  subject: "",
  message: "",
});

const isValid = () => {
  errors.name = validateName(formData.name);
  errors.email = validateEmail(formData.email);
  errors.subject = validateName(formData.subject);
  errors.message = validateName(formData.message);
  return Object.values(errors).every((error) => error === "");
};

const sendMessage = () => {
  if (isValid()) {
    try {
      axios.post("/api/contact-form", {
        name: formData.name,
        email: formData.email,
        subject: formData.subject,
        message: formData.message,
      });
      emit("closeDialog");
      useToast().success($t("contact_form.message_sent_successfully"));
    } catch (error) {
      useToast().error($t("notifications.default_error"));
      console.error(error);
    }
  }
};
</script>
<template>
  <v-card>
    <v-form @submit.prevent="sendMessage">
      <slot name="close" />
      <v-card-title v-if="withTitle" class="dialog-title">
        {{ $t("contact_form.contact_support") }}
      </v-card-title>
      <v-card-text class="dialog-content">
        <v-text-field
          v-model="formData.name"
          :error-messages="errors.name"
          :label="$t('contact_form.name')"
          density="comfortable"
          variant="outlined"
          required
        />
        <v-text-field
          v-model="formData.email"
          :error-messages="errors.email"
          :label="$t('contact_form.email')"
          density="comfortable"
          variant="outlined"
          required
        />
        <v-select
          v-model="formData.subject"
          :error-messages="errors.subject"
          :label="$t('contact_form.subject')"
          :items="subjectTypes"
          density="comfortable"
          variant="outlined"
          required
        />
        <v-textarea
          v-model="formData.message"
          :error-messages="errors.message"
          :label="$t('contact_form.describe_your_problem')"
          density="comfortable"
          variant="outlined"
          required
        />
        <v-btn
          type="submit"
          :text="$t('contact_form.send')"
          block
          variant="flat"
          class="form-button"
        />
        <img class="contact-icon" src="/src/assets/images/contact.png" alt="Contact form" />
      </v-card-text>
    </v-form>
  </v-card>
</template>

<style scoped>
.dialog-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 5px 5px 0px;
  text-align: center;
}

.dialog-content {
  color: #858788;
  padding-top: 15px;
  padding-left: 63px;
  padding-right: 63px;
}

.form-button {
  background-color: var(--booger-buster);
  color: var(--eerie-black) !important;
  font-size: 1.125rem;
  font-weight: bold;
  height: 41px;
}

.contact-icon {
  width: 100%;
  margin-top: 20px;
  height: 333px;
}

@media (max-width: 768px) {
  .dialog-content {
    padding: 15px 0;
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
}
</style>

<script setup lang="ts">
import { useToast } from "vue-toastification";
import ShareDocumentViaEmailDialog from "@/components/documents/navigator/partials/ShareDocumentViaEmailDialog.vue";
import printJS from "print-js";
import { Document } from "@/types/editor/Document";
import { useI18n } from "vue-i18n";
import axios from "axios";
import { useStore } from "vuex";
import SaveAsDialog from "@/components/documents/navigator/partials/SaveAsDialog.vue";
import MoveToDirectoryDialog from "@/components/documents/navigator/partials/MoveToDirectoryDialog.vue";
import RegisterDialog from "@/components/dialogs/RegisterDialog.vue";
import SubscriptionRequiredDialog from "@/components/dialogs/SubscriptionRequiredDialog.vue";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { useIsMobile } from "@/utils/checkMobile";
import { uploadDocumentToDocusign } from "@/plugins/docusign";

const router = useRouter();

interface MenuItem {
  label: string;
  action?: () => void;
  requiresAuth?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  locked?: boolean;
  hint?: string;
}

const emit = defineEmits([
  "showHistoryModal",
  "showShareHistoryDialog",
  "updateDocument",
  "deletedDocument",
  "toggleAiAssistant",
]);
const store = useStore();

const { document, hasWizardCompleted, buttons } = defineProps<{
  document: Document;
  hasWizardCompleted: boolean;
  buttons: string[];
}>();

const directories = ref([]);
const isTemporaryUser = computed(() => store.state.auth.user.isTemporary);

const $t = useI18n().t;
const isMobile = useIsMobile();
const showShareViaEmailDialog = () => {
  isShareViaEmailDialogOpen.value = true;
};

const isShareViaEmailDialogOpen = ref(false);
const isSaveAsDialogOpen = ref(false);
const isMoveToDirectoryDialogOpen = ref(false);
const isRegisterDialogOpen = ref(false);
const isSubscriptionDialogOpen = ref(false);

const showHistoryModal = () => {
  emit("showHistoryModal");
};

const showShareHistoryDialog = () => {
  emit("showShareHistoryDialog");
};

const toast = useToast();

const isPaid = computed(() => document.is_paid);

const copyLink = () => {
  navigator.clipboard.writeText(document.download_url);

  toast.success($t("notifications.link_copied"));
};

const isReadyForShare = computed(() => document.download_url);
const userHasSubscription = computed(() => store.state.auth.user.hasValidSubscription);

const printDocument = () => {
  if (isTemporaryUser.value) {
    showRegisterDialog();
    return;
  }

  if (!isPaid.value && !userHasSubscription.value) {
    showSubscriptionDialog();
    return;
  }

  printJS(document.download_url);
};

const printDocumentForVisitor = () => {
  printJS(document.download_url);
};

const downloadDocument = () => {
  window.open(document.download_url);
};

const showRegisterDialog = () => {
  isRegisterDialogOpen.value = true;
};

const showSubscriptionDialog = () => {
  isSubscriptionDialogOpen.value = true;
};

const edit = () => {
  router.push(`/new-document-creator/${document.id}`);
};

const showComments = () => {
  const commentsPageUrl = new URL(document.negotiation_url);
  const params = new URLSearchParams(commentsPageUrl.search);
  const token = params.get("token");

  router.push(`/documents/${document.id}/approve?token=${token}`);
};

const signDocument = async () => {
  uploadDocumentToDocusign(document.id);
};

const save = () => {
  if (!hasWizardCompleted || !store.state.editor.someChangesToSave) {
    return;
  }

  axios
    .post(`api/documents/${document.id}?_method=PUT`, {
      name: document.name,
      content: store.state.editor.content,
      template_id: store.state.editor.templateId,
      directory_id: document.directory?.id,
    })
    .then((response) => {
      toast.success($t("notifications.document_saved"));
      store.commit("editor/switchContentChangeStatus", false);
    })
    .catch((error) => {
      if (error.response.status === 402) {
        toast.error($t("documents.subscription_or_single_payment_required"));
      } else {
        toast.error($t("notifications.document_save_error"));
      }
    });
};

const saveAs = () => {
  if (isTemporaryUser.value) {
    showRegisterDialog();
    return;
  }

  isSaveAsDialogOpen.value = true;
};

const moveToDirectory = () => {
  if (isTemporaryUser.value) {
    showRegisterDialog();
    return;
  }

  if (!userHasSubscription.value) {
    showSubscriptionDialog();
    return;
  }

  isMoveToDirectoryDialogOpen.value = true;
};

const deleteDocument = () => {
  axios
    .delete(`api/documents/${document.id}`)
    .then(() => {
      toast.success($t("notifications.document_deleted"));
      emit("deletedDocument");
    })
    .catch(() => {
      toast.error($t("notifications.document_delete_error"));
    });
};

const duplicate = () => {
  if (isTemporaryUser.value) {
    showRegisterDialog();
    return;
  }

  if (!userHasSubscription.value) {
    showSubscriptionDialog();
    return;
  }

  axios
    .post(`api/documents/${document.id}/copy`)
    .then((response) => {
      toast.success($t("notifications.document_duplicated"));
      router.push({
        path: `/new-document-creator/${response.data.data.id}`,
      });
    })
    .catch(() => {
      toast.error($t("notifications.document_duplicate_error"));
    });
};

const toggleAiAssistant = () => {
  emit("toggleAiAssistant");
};

const visitorItems: MenuItem[] = [
  {
    label: $t("common.download"),
    action: downloadDocument,
    requiresAuth: false,
    disabled: false,
  },
  {
    label: $t("common.print"),
    action: printDocumentForVisitor,
    requiresAuth: false,
    disabled: false,
    hidden: isMobile,
  },
  {
    label: $t("documents.show_history"),
    action: showHistoryModal,
  },
];

const editItems: MenuItem[] = [
  {
    label: $t("common.edit"),
    action: edit,
  },
  {
    label: $t("documents.show_comments"),
    action: showComments,
    requiresAuth: true,
    disabled: hasWizardCompleted === false,
  },
  {
    label: $t("documents.move_to_directory"),
    action: moveToDirectory,
    requiresAuth: true,
    locked: isTemporaryUser.value || !userHasSubscription.value,
  },
  {
    label: $t("common.print"),
    action: printDocument,
    requiresAuth: false,
    disabled: document.status === "Draft",
    hidden: isMobile,
  },
  {
    label: $t("common.download"),
    action: downloadDocument,
    requiresAuth: false,
    disabled: document.status === "Draft",
  },
  {
    label: $t("common.sign"),
    action: signDocument,
    requiresAuth: true,
    hint: $t("documents.sign_hint"),
    disabled: hasWizardCompleted === false,
  },
];

const fileItems = computed<MenuItem[]>(() => {
  return [
    {
      label: $t("common.save"),
      action: save,
      disabled: hasWizardCompleted === false || !store.state.editor.someChangesToSave,
    },
    {
      label: $t("common.save_as"),
      action: saveAs,
      requiresAuth: true,
      locked: isTemporaryUser.value,
    },
    {
      label: $t("documents.show_comments"),
      action: showComments,
      requiresAuth: true,
      disabled: hasWizardCompleted === false,
    },
    {
      label: $t("documents.move_to_directory"),
      action: moveToDirectory,
      requiresAuth: true,
      locked: isTemporaryUser.value || !userHasSubscription.value,
    },
    {
      label: $t("common.download"),
      action: downloadDocument,
      requiresAuth: false,
      disabled: document.status === "Draft",
    },
    {
      label: $t("documents.duplicate"),
      action: duplicate,
      requiresAuth: true,
      locked: isTemporaryUser.value || !userHasSubscription.value,
    },
    {
      label: $t("common.print"),
      action: printDocument,
      requiresAuth: false,
      disabled: (!isPaid.value && !userHasSubscription.value) || document.status === "Draft",
      hidden: isMobile,
    },
    {
      label: $t("documents.show_history"),
      action: showHistoryModal,
    },
    {
      label: $t("common.sign"),
      action: signDocument,
      requiresAuth: true,
      locked: isTemporaryUser.value || (!userHasSubscription.value && !isPaid.value),
      disabled: hasWizardCompleted === false,
      hint: $t("documents.sign_hint"),
    },
  ];
});

const shareItems: MenuItem[] = [
  {
    label: $t("creator.copy_link"),
    action: copyLink,
    requiresAuth: false,
    disabled: document.status === "Draft",
  },
  {
    label: $t("creator.share_email"),
    action: showShareViaEmailDialog,
    requiresAuth: false,
    disabled: document.status === "Draft",
  },
  {
    label: $t("documents.send_to_verification"),
    action: showShareViaEmailDialog,
    requiresAuth: false,
    disabled: document.status === "Draft",
  },
  {
    label: $t("documents.show_history"),
    action: showShareHistoryDialog,
  },
];

const previewShareItems: MenuItem[] = [
  {
    label: $t("creator.copy_link"),
    action: copyLink,
    requiresAuth: false,
    disabled: document.status === "Draft",
  },
  {
    label: $t("creator.share_email"),
    action: showShareViaEmailDialog,
    requiresAuth: false,
    disabled: document.status === "Draft",
  },
  {
    label: $t("documents.send_to_verification"),
    action: showShareViaEmailDialog,
    requiresAuth: false,
    disabled: document.status === "Draft",
  },
];

const handleDocumentUpdated = () => {
  emit("updateDocument");
};

const handleSaveByShortcut = (event) => {
  const isShortcutClicked = event.ctrlKey && event.key === "s";

  if (isShortcutClicked) {
    event.preventDefault();
    save();
  }
};

onMounted(async () => {
  try {
    if (userHasSubscription.value) {
      const { data } = await axios.get("/api/directories");
      directories.value = data.data;
    }
  } catch (error) {
    directories.value = [];
  }

  window.addEventListener("keydown", handleSaveByShortcut);
});
onUnmounted(() => {
  window.removeEventListener("keydown", handleSaveByShortcut);
});
</script>
<template>
  <div>
    <!-- Desktop View -->
    <div class="navigator" v-if="!isMobile">
      <!-- VISITOR -->
      <v-menu v-if="buttons.includes('visitor')">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="text" class="padding file-btn border-sm">
            {{ $t("common.file") }}

            <template v-slot:append>
              <v-icon icon="mdi-menu-down"></v-icon>
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in visitorItems.filter((item) => !item.hidden)"
            :key="index"
            :value="index"
            @click="item.action"
            :disabled="item.disabled"
          >
            <template v-if="item.requiresAuth && item.locked" v-slot:prepend>
              <v-icon icon="mdi-lock"></v-icon>
            </template>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- EDIT -->
      <v-menu v-if="buttons.includes('edit')">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="text" class="padding file-btn border-sm">
            {{ $t("common.file") }}

            <template v-slot:append>
              <v-icon icon="mdi-menu-down"></v-icon>
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in editItems.filter((item) => !item.hidden)"
            :key="index"
            :value="index"
            @click="item.action"
            :disabled="item.disabled"
          >
            <template v-if="item.requiresAuth && item.locked" v-slot:prepend>
              <v-icon icon="mdi-lock"></v-icon>
            </template>

            <v-tooltip location="bottom" :text="item.hint">
              <template v-slot:activator="{ props }">
                <div v-bind="item.hint && item.disabled && props" style="pointer-events: auto">
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </div>
              </template>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- FILE -->
      <v-menu v-if="buttons.includes('file')">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="text" class="padding file-btn border-sm">
            {{ $t("common.file") }}

            <template v-slot:append>
              <v-icon icon="mdi-menu-down"></v-icon>
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in fileItems.filter((item) => !item.hidden)"
            :key="index"
            :value="index"
            @click="item.action"
            :disabled="item.disabled"
          >
            <template v-if="item.requiresAuth && item.locked" v-slot:prepend>
              <v-icon icon="mdi-lock"></v-icon>
            </template>

            <v-tooltip location="bottom" :text="item.hint">
              <template v-slot:activator="{ props }">
                <div v-bind="item.hint && item.disabled && props" style="pointer-events: auto">
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </div>
              </template>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- PREVIEW SHARE -->
      <v-menu v-if="buttons.includes('preview-share')">
        <template v-slot:activator="{ props }">
          <v-btn
            variant="text"
            class="padding share-btn border-sm"
            v-bind="props"
            :disabled="!isReadyForShare"
          >
            {{ $t("common.share") }}

            <template v-slot:append>
              <img src="/src/assets/icons/share.svg" alt="" />
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in previewShareItems.filter((item) => !item.hidden)"
            :key="index"
            :value="index"
            @click="item.action"
          >
            <template v-if="item.requiresAuth && item.locked" v-slot:prepend>
              <v-icon icon="mdi-lock"></v-icon>
            </template>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- SHARE -->
      <v-menu v-if="buttons.includes('share')">
        <template v-slot:activator="{ props }">
          <v-btn
            variant="text"
            class="padding share-btn border-sm"
            v-bind="props"
            :disabled="!isReadyForShare"
          >
            {{ $t("common.share") }}

            <template v-slot:append>
              <img src="/src/assets/icons/share.svg" alt="" />
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in shareItems.filter((item) => !item.hidden)"
            :key="index"
            :value="index"
            @click="item.action"
          >
            <template v-if="item.requiresAuth && item.locked" v-slot:prepend>
              <v-icon icon="mdi-lock"></v-icon>
            </template>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- DELETE -->
      <v-btn v-if="buttons.includes('delete')" color="primary" @click="deleteDocument">
        {{ $t("common.delete") }}
      </v-btn>

      <!-- AI Assistant -->
      <v-btn
        v-if="buttons.includes('ai')"
        class="assistant-btn"
        color="var(--light-grey)"
        @click="toggleAiAssistant"
        variant="flat"
      >
        <template v-slot:prepend>
          <img src="/src/assets/icons/virtual-assistant-header.svg" alt="" />
        </template>
        {{ $t("chat.ai_assistant") }}
      </v-btn>
    </div>

    <!-- Mobile View -->
    <div v-else>
      <v-menu width="100%" class="navigator-mobile">
        <template v-slot:activator="{ props }">
          <v-btn class="menu-button" variant="flat" v-bind="props">
            <img src="/images/icons/dots-line.svg" alt="menu icon" />
          </v-btn>
        </template>

        <!-- VISITOR -->
        <v-list rounded="0" density="compact" v-if="buttons.includes('visitor')">
          <v-list-subheader>
            {{ $t("common.file") }}
          </v-list-subheader>
          <v-list>
            <v-list-item
              v-for="(item, index) in visitorItems.filter((item) => !item.hidden)"
              :key="index"
              :value="index"
              @click="item.action"
              :disabled="item.disabled"
            >
              <template v-if="item.requiresAuth && item.locked" v-slot:prepend>
                <v-icon icon="mdi-lock"></v-icon>
              </template>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list>

        <!-- EDIT -->
        <v-list rounded="0" density="compact" v-if="buttons.includes('edit')">
          <v-list-subheader>
            {{ $t("common.file") }}
          </v-list-subheader>
          <v-list>
            <v-list-item
              v-for="(item, index) in editItems.filter((item) => !item.hidden)"
              :key="index"
              :value="index"
              @click="item.action"
              :disabled="item.disabled"
            >
              <template v-if="item.requiresAuth && item.locked" v-slot:prepend>
                <v-icon icon="mdi-lock"></v-icon>
              </template>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list>

        <!-- FILE -->
        <v-list rounded="0" density="compact" v-if="buttons.includes('file')">
          <v-list-subheader>
            {{ $t("common.file") }}
          </v-list-subheader>
          <v-list-item
            v-for="(item, index) in fileItems.filter((item) => !item.hidden)"
            :key="index"
            :value="index"
            @click="item.action"
            :disabled="item.disabled"
          >
            <template v-if="item.requiresAuth && item.locked" v-slot:prepend>
              <v-icon icon="mdi-lock"></v-icon>
            </template>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <!-- PREVIEW SHARE !-->
        <v-list rounded="0" density="compact" v-if="buttons.includes('preview-share')">
          <v-list-subheader>
            {{ $t("common.share") }}
          </v-list-subheader>
          <v-list-item
            v-for="(item, index) in previewShareItems.filter((item) => !item.hidden)"
            :key="index"
            :value="index"
            @click="item.action"
          >
            <template v-if="item.requiresAuth" v-slot:prepend>
              <v-icon icon="mdi-lock"></v-icon>
            </template>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <!-- SHARE !-->
        <v-list rounded="0" density="compact" v-if="buttons.includes('share')">
          <v-list-subheader>
            {{ $t("common.share") }}
          </v-list-subheader>
          <v-list-item
            v-for="(item, index) in shareItems.filter((item) => !item.hidden)"
            :disabled="!isReadyForShare"
            :key="index"
            :value="index"
            @click="item.action"
          >
            <template v-if="item.requiresAuth && item.locked" v-slot:prepend>
              <v-icon icon="mdi-lock"></v-icon>
            </template>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- SHARE DOCUMENT VIA EMAIL DIALOG -->
    <ShareDocumentViaEmailDialog
      :is-dialog-open="isShareViaEmailDialogOpen"
      @close-dialog="isShareViaEmailDialogOpen = false"
      :document="document"
    />

    <SaveAsDialog
      :is-dialog-open="isSaveAsDialogOpen"
      @close-dialog="isSaveAsDialogOpen = false"
      @document-updated="handleDocumentUpdated"
      :document="document"
    />

    <MoveToDirectoryDialog
      :is-dialog-open="isMoveToDirectoryDialogOpen"
      @close-dialog="isMoveToDirectoryDialogOpen = false"
      @document-updated="handleDocumentUpdated"
      :document="document"
      :directories="directories"
    />

    <RegisterDialog
      :is-dialog-open="isRegisterDialogOpen"
      @close-dialog="isRegisterDialogOpen = false"
    />

    <SubscriptionRequiredDialog
      :is-dialog-open="isSubscriptionDialogOpen"
      @close-dialog="isSubscriptionDialogOpen = false"
    />
  </div>
</template>

<style scoped lang="scss">
.padding {
  padding: 20px !important;
}

.navigator-mobile {
  width: 100%;
  display: block;
  margin: 0 !important;
  padding: 0 !important;
}

.menu-button {
  min-width: 44px !important;
  max-width: 44px !important;
  min-height: 42px !important;
  max-height: 42px !important;
  border: 1px solid var(--grey) !important;
  background-color: white !important;
}

.navigator {
  display: flex;
  gap: 15px;

  .v-btn {
    text-transform: none;
    display: flex;
    height: 42px;
  }

  .file-btn {
    gap: 100px;
  }

  .share-btn {
    gap: 50px;
  }

  .assistant-btn {
    font-weight: 700;
  }
}
</style>

<script setup lang="ts">
import defaultSchema from "@/components/ProseMirror/schemas/defaultSchema";
import CustomToolbar from "@/components/ProseMirror/menu/CustomToolbar.vue";

import { watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { Node } from "prosemirror-model";
import { columnResizing, tableEditing } from "prosemirror-tables";
import { keymap } from "prosemirror-keymap";
import { baseKeymap } from "prosemirror-commands";
import { history } from "prosemirror-history";

import { applyStyleToVariable } from "@/utils/editorStyling";
import parseXMLToJSON from "@/utils/xmlParser/parseXMLToJSON";
import useKeymap from "./keymap/useKeymap";

const { document, editable } = defineProps<{
  document: Object;
  editable: Boolean;
}>();

const emit = defineEmits(["documentUpdated", "variableClicked"]);

const { t } = useI18n();
const store = useStore();

const templateId = ref(null);
const editorRef = ref(null);
const contentRef = ref(null);
let editorView = null;
const lastSnapshotSavedAt = ref(0);
const isLoaded = ref(false);
const mobileMenuKey = ref(0);

const isWizardCompleted = computed(() => store.state.editor.wizard.isCompleted);
const isWizardVariableCompleted = computed(() => store.state.editor.are_variables_set);

const handleVariableClick = (id, name, description, defaultText, answer) => {
  const isTheSameVariable = store.state.editor.editableVariable.id === id;

  if (!isWizardCompleted.value || !isWizardVariableCompleted.value || isTheSameVariable) {
    return;
  }

  emit("variableClicked");

  const clickedVariable = store.state.editor.availableVariables.find(
    (variable) => variable.id === id,
  );

  const type = clickedVariable.type ?? "text";

  let variable = {
    name: name,
    value: store.state.editor.variables[id] ?? defaultText,
    description: description,
    type: type,
    id: id,
    answer: answer,
  };

  if (type === "select") {
    const options = variable.answer.split("\n");

    variable = {
      ...variable,
      value: options,
      answer: options,
      options: clickedVariable.options,
      max_options: clickedVariable.max_options,
      list_type: clickedVariable.list_type,
    };
  }

  store.commit("editor/updateEditableVariable", variable);

  applyStyleToVariable(id);
};

const mySchema = defaultSchema;
const customKeymap = useKeymap(mySchema);

const checkForErrors = (documentContent) => {
  try {
    const node = Node.fromJSON(mySchema, toRaw(documentContent));
    node.content.content[0].content.content.forEach((child) => {});
  } catch (error) {
    console.error("Error checking document", error);
  }
};

async function sendDocumentToApi(newDocumentState) {
  try {
    const response = await axios.post(`api/documents/${document.id}?_method=PUT`, {
      name: document.name,
      content: JSON.stringify(newDocumentState),
      template_id: templateId.value,
    });

    await axios.post(`api/documents/${document.id}/history`, {
      content: JSON.stringify(newDocumentState),
      created_at: new Date().toISOString(),
    });
  } catch (error) {
    console.error(error.response.data.message);
  }
}

onMounted(async () => {
  templateId.value = document.template_id;
  store.commit("editor/setTemplateId", document.template_id);
  const contentData = document.content;
  const parsedContent = parseXMLToJSON(contentData);

  let counter = 0;
  //error listener do wyłapywania nieobsłużonych błędów z prosemirror
  window.onerror = (message, source, lineno, colno, error) => {
    if (error?.message.includes("contentMatchAt") && counter < 1) {
      counter++;
      useToast().error(t("errors.editor.prohibited_action"));
    }
    setTimeout(() => {
      counter = 0;
    }, 3000);
  };

  if (editorRef.value) {
    //Uncomment this if you want to check each node for possible errors in the schema
    // checkForErrors(parsedContent);

    const content = Node.fromJSON(mySchema, toRaw(parsedContent));

    const plugins = [
      keymap(customKeymap),
      keymap(baseKeymap),
      history(),
      columnResizing(),
      tableEditing(),
    ];

    editorView = new EditorView(editorRef.value, {
      state: EditorState.create({
        doc: content,
        plugins,
      }),
      async dispatchTransaction(transaction) {
        mobileMenuKey.value += 1;
        const newState = editorView.state.apply(transaction);
        editorView.updateState(newState);

        const newDocumentState = transaction.doc.toJSON();
        checkForErrors(newDocumentState);

        // We save a snapshot of content only when changes are being made and only if at least 2 seconds passed since last snapshot
        store.commit("editor/updateContent", JSON.stringify(newDocumentState));

        const isTimeToAutoSave =
          transaction.time - lastSnapshotSavedAt.value >= 5000 && transaction.updated > 1;
        const someChanges = transaction.updated > 1;
        const isVariableEdited = transaction.curSelectionFor > 0;

        store.commit("editor/switchContentChangeStatus", true);

        if ((isTimeToAutoSave && someChanges) || isVariableEdited) {
          lastSnapshotSavedAt.value = transaction.time;
          await sendDocumentToApi(newDocumentState);
        }
      },
      handleDOMEvents: {
        click: (view, event) => {
          event.preventDefault();
          const findParentElement = (element) => {
            if (!element) {
              return null;
            }
            if (element.nodeName === "VARIABLE") {
              return element;
            } else {
              return findParentElement(element.parentElement);
            }
          };

          const target = findParentElement(event.target);

          if (target && target.matches("variable[data-name]")) {
            handleVariableClick(
              target.getAttribute("data-id"),
              target.getAttribute("data-name"),
              target.getAttribute("data-description"),
              target.getAttribute("data-answer"),
              target.innerText,
            );

            return true;
          }
          return false;
        },
      },
      editable: () => editable,
    });
    isLoaded.value = true;
  }

  //Updates existing editor values when input in store changes
  watch(
    () => store.state.editor.variables,
    (newVariables) => {
      const tr = editorView.state.tr;
      let updated = false;
      editorView.state.doc.descendants((node, pos) => {
        if (node.type.name === "variable") {
          const varId = node.attrs.id;
          if (newVariables[varId] && node.attrs.value !== newVariables[varId]) {
            const newValue = newVariables[varId];

            if (newValue !== undefined) {
              const newNode = node.type.create({
                ...node.attrs,
                value: newValue,
                answer: newValue,
              });
              tr.replaceWith(pos, pos + node.nodeSize, newNode);
              updated = true;
            }
          }
        }
      });

      if (updated) {
        editorView.dispatch(tr);
      }
    },
    { deep: true },
  );

  watch(
    () => store.state.editor.restoreVersion,
    (version) => {
      if (version) {
        let parsedContent;
        try {
          parsedContent = JSON.parse(version);
        } catch (error) {
          parsedContent = version;
        }

        const content = Node.fromJSON(mySchema, toRaw(parsedContent));
        editorView.updateState(
          EditorState.create({
            doc: content,
            plugins: [
              keymap(customKeymap),
              keymap(baseKeymap),
              history(),
              columnResizing(),
              tableEditing(),
            ],
          }),
        );
        store.dispatch("editor/commitTransaction", content.toJSON());

        axios.post(`api/documents/${document.id}?_method=PUT`, {
          name: document.name,
          content: JSON.stringify(content.toJSON()),
          template_id: templateId.value,
        });

        axios.post(`api/documents/${document.id}/history`, {
          content: JSON.stringify(content.toJSON()),
          created_at: new Date().toISOString(),
        });
      }
    },
  );
});
</script>

<template>
  <CustomToolbar
    v-if="isLoaded"
    :editorView="editorView"
    :changeKey="mobileMenuKey"
    :disabled="!editable"
  />
  <div ref="editorRef" id="editor" class="editor"></div>
  <div ref="contentRef" id="content" class="editor-content"></div>
</template>

<style scoped lang="scss">
.editor {
  height: 100%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: text;
  @media (max-width: 768px) {
    border: none !important;
    max-height: calc(100vh - 60px - 50px - 80px);
    overflow: auto;
  }
}

.editor::-webkit-resizer {
  content: "";
  background: url("/images/icons/vertical-resize.svg") no-repeat center center;
  background-size: 80%;
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
}

.editor-content {
  display: none;
}
</style>

import useCookies from "@/utils/cookies";

const cookies = useCookies();

const authModule = {
  namespaced: true,
  state() {
    return {
      isLoggedIn: localStorage?.getItem("isLogin") === "1",
      apiToken: localStorage?.apiToken,
      user: {
        id: null,
        name: "",
        email: "",
        isTemporary: false,
        isEmailVerified: false,
        subscription: null,
        disclaimer: {
          is_accepted_temporarily: false,
          accepted_at: null,
        },
        hasTermsAccepted: false,
      },
    };
  },
  mutations: {
    login(state, token) {
      state.isLoggedIn = true;
      localStorage.setItem("isLogin", "1");
      localStorage.apiToken = token;
      cookies.set("apiToken", token);
    },
    logout(state) {
      state.isLoggedIn = false;
      state.apiToken = null;
      state.user = {
        name: "",
        email: "",
        hasTermsAccepted: state.user.hasTermsAccepted,
      };
      localStorage.removeItem("isLogin");
      localStorage.removeItem("apiToken");
      cookies.delete("apiToken");
    },
    setUser(state, user) {
      state.user = user;
    },
    setTermsAcceptance(state) {
      state.user.hasTermsAccepted = true;
    },
  },
};

export default authModule;

<script setup>
import ToolbarTabs from "@/components/ProseMirror/menu/ToolbarTabs.vue";
import ToolbarTabContent from "@/components/ProseMirror/menu/ToolbarTabContent.vue";

const {
  state,
  dispatch,
  editorView,
  changeKey,
  disabled = false,
} = defineProps(["state", "dispatch", "editorView", "changeKey", "disabled"]);

const tabsList = [
  {
    name: "FONT",
    icon: "font",
  },
  {
    name: "FORMAT",
    icon: "format",
  },
  {
    name: "COLOR",
    icon: "color",
  },
  {
    name: "SPACING",
    icon: "spacing",
  },
];

const activeTab = ref(null);

const handleChangeTab = (selectedTab) => {
  activeTab.value = selectedTab;
};

watch(
  () => changeKey,
  () => {
    activeTab.value = null;
  },
);
</script>
<template>
  <div class="toolbar-wrapper">
    <div class="toolbar">
      <ToolbarTabs
        :tabs="tabsList"
        :activeTab="activeTab"
        :editorView="editorView"
        :disabled="disabled"
        @changeTab="handleChangeTab"
      />

      <ToolbarTabContent
        :activeTab="activeTab"
        :editorView="editorView"
        @changeTab="handleChangeTab"
        :key="changeKey"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.toolbar-wrapper {
  width: 100%;
}
.toolbar {
  width: 100%;
  background-color: white;
}
</style>

<script setup>
import { undo, redo } from "prosemirror-history";

const { editorView } = defineProps(["editorView"]);

const handleUndo = () => {
  undo(editorView.state, editorView.dispatch);
};

const handleRedo = () => {
  redo(editorView.state, editorView.dispatch);
};

const icons = [
  { name: "undo", action: handleUndo },
  { name: "redo", action: handleRedo },
];
</script>
<template>
  <div class="undo-redo">
    <div class="arrow" v-for="icon in icons" @click="icon.action">
      <div
        class="arrow-inner"
        :style="`mask: url(/images/icons/editor-toolbar/${icon.name}.svg) no-repeat center`"
      ></div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.undo-redo {
  display: flex;
  gap: 5px;

  .arrow {
    border: 1px solid white;
    transition: 0.2s ease;

    .arrow-inner {
      width: 30px;
      height: 30px;
      background-color: var(--grey);
      transition: 0.2s ease;
    }

    &:hover {
      border: 1px solid var(--grey);
      cursor: pointer;

      .arrow-inner {
        background-color: var(--blue-bonnet);
      }
    }
  }
}
</style>

<script setup>
import MessageInput from "@/components/chat/messages/MessageInput.vue";
import ChatContent from "@/components/chat/messages/ChatContent.vue";
import SampleMessages from "@/components/chat/messages/SampleMessages.vue";
import RecommendedButtons from "@/components/chat/messages/RecommendedButtons.vue";
import Disclaimer from "@/components/chat/Disclaimer.vue";

import axios from "axios";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useChatCommands } from "@/utils/chatCommands/useChatCommands";
import parseToXml from "@/utils/jsonParser/parseToXml";
import isJson from "@/utils/checkIsJSON";

import { useChatToken } from "./useChatToken";
import { useChatWebSocket } from "./useChatWebSocket";
import { useHelpUser } from "./useHelpUser";

const {
  currentDocument = null,
  minimized = false,
  isFullScreenChat = false,
  isResizeEnabled = false,
} = defineProps(["currentDocument", "minimized", "isFullScreenChat", "isResizeEnabled"]);

const emit = defineEmits(["showChat", "toggleFullScreenChat"]);

const router = useRouter();
const store = useStore();
const toast = useToast();
const { t } = useI18n();

const isChatMinimized = ref(false);
const isChatDisabled = ref(false);
const isDisclaimerVisible = ref(false);
const isSecodnDisclaimerVisible = ref(true);
const isResponseLoading = computed(() => store.state.chat.isResponseLoading);
const isWizardComleted = computed(() => store.state.editor.wizard.isCompleted);

const messages = ref([]);

const user = computed(() => store.state.auth.user);
const state = computed(() => {
  const documentContent = currentDocument?.content;
  if (!documentContent) {
    return "general";
  }

  if (!isWizardComleted.value) {
    return "editing_blur_full";
  }

  if (!currentDocument.is_paid && !user.value.hasValidSubscription) {
    return "editing_blur_partial";
  }

  return "editing_full";
});

const chatId = computed(() =>
  state.value.includes("editing") && currentDocument.id ? currentDocument.id : user.value.id,
);
const page = ref(1);

const createWelcomeMessage = () => {
  return [
    {
      id: crypto.randomUUID(),
      source: "bot",
      message:
        state.value === "general"
          ? t("chat.new_document_welcome_message")
          : t("chat.creator_welcome_message"),
    },
  ];
};

const handleScrollPosition = async (currentPage) => {
  const chatContent = chatContentREF.value;
  if (!chatContent) return;

  if (currentPage === 1) {
    await nextTick();
    scrollToBottom();
  } else {
    const lastHeight = chatContent.scrollHeight;
    await nextTick();
    const newHeight = chatContent.scrollHeight;
    chatContent.scrollTop = newHeight - lastHeight;
  }
};

const getChatHistory = async (event) => {
  const isDocumentChat = !!currentDocument;
  const url = isDocumentChat
    ? `api/documents/${currentDocument?.id}/chat-messages`
    : `api/chat-messages`;

  try {
    const response = await axios.get(url, { params: { page: page.value } });
    const { data: messagesList, current_page, next_page_url } = response.data;

    if (messagesList.length === 0 && page.value === 1) {
      messages.value = createWelcomeMessage();
    } else {
      messages.value.unshift(...messagesList);
    }

    await handleScrollPosition(current_page);

    if (!next_page_url) {
      event?.done("empty");
    } else {
      await event?.done();
      page.value++;
    }
  } catch (error) {
    console.error("Error getting messages history", error);
  }
};

const saveMessageOnServer = async (messageContent, source = "user") => {
  console.log("Saving message on server", messageContent);
  const body = {
    chat_id: chatId.value,
    message: messageContent,
    source,
  };

  const apiHost = `${import.meta.env.VITE_APP_API_BASE_URL}/api`;
  const documentChatURL = `${apiHost}/documents/${currentDocument?.id}/chat-messages`;
  const generalChatURL = `${apiHost}/chat-messages`;

  await axios.post(currentDocument ? documentChatURL : generalChatURL, body);
};

const showDisclaimer = () => {
  isDisclaimerVisible.value = true;
};

const setSecondDisclaimerVisibility = (state) => {
  isSecodnDisclaimerVisible.value = state;
};

const { token, tokenExpired, getChatToken } = useChatToken(chatId);
const { socket, setupWebsocket } = useChatWebSocket(
  token,
  messages,
  store,
  t,
  saveMessageOnServer,
  showDisclaimer,
);
const { handleNoAction } = useHelpUser(
  currentDocument,
  store,
  token,
  chatId,
  state,
  socket,
  isJson,
  parseToXml,
);

defineExpose({
  handleNoAction,
});

const showChat = () => {
  isChatMinimized.value = false;
  emit("showChat");
};

const handleRecommendedButtonClick = async (clickedButton) => {
  console.log("Clicked button: ", clickedButton);
  if (clickedButton.type === "create_document") {
    const response = await axios.post("api/documents", {
      template_id: clickedButton.template_uuid,
    });

    const templateId = response.data.data.id;

    store.commit("editor/updateDocumentId", templateId);
    store.commit("chat/updateSessionId", templateId);

    router.push(`/new-document-creator/${templateId}`);
  }
};

//work in progress (waiting for awakast). do not remove
// const disableChat = () => {
//   isChatDisabled.value = true;
//   messages.value.push({
//     id: crypto.randomUUID(),
//     source: "bot",
//     message:
//       state.value === "editing"
//         ? t("errors.chat.limit_document")
//         : t("errors.chat.limit_general"),
//   });
// };

const handleSendMessage = async (messageContent) => {
  console.log("Sending messaage with content: " + messageContent);
  const isEmpty = messageContent.trim() === "";

  if (isEmpty || !socket.value) return;

  useChatCommands({
    command: "remove_selection_from_paragraphs",
  });

  saveMessageOnServer(messageContent, "user");

  const messageId = crypto.randomUUID();
  const documentContent =
    store.state.editor.content.content ||
    (store.state.editor.content ? store.state.editor.content : currentDocument?.content);

  const metadata = currentDocument
    ? {
        document: isJson(documentContent)
          ? parseToXml(documentContent, currentDocument?.meta)
          : documentContent,
        template_uuid: currentDocument ? currentDocument?.template_awakast_id : null,
      }
    : {};

  //ADD WIZARD DATA TO META
  if (state.value.includes("editing")) {
    const stepID = store.state.editor?.wizard?.currentStep || null;
    const variableId = store.state.editor?.editableVariable?.id || null;
    stepID && (metadata.step_id = stepID);
    variableId && (metadata.variable_id = variableId);
  }

  const message = {
    token: token.value,
    message_id: messageId,
    document_id: currentDocument ? currentDocument.id : null,
    chat_id: chatId.value,
    input: messageContent,
    state: { state: state.value },
    metadata: metadata,
    template_title: currentDocument ? currentDocument?.meta?.title : null,
    template_uuid: currentDocument ? currentDocument?.template_id : null,
  };

  const simpleMessage = {
    id: messageId,
    source: "user",
    message: messageContent,
  };
  messages.value.push(simpleMessage);

  console.log("message", message);
  store.commit("chat/updateIsResponseLoading", true);
  socket.value.send(JSON.stringify(message));

  console.log("Sending successful");
};

const toggleFullScreenChat = () => {
  emit("toggleFullScreenChat");
  scrollToBottom();
};

const chatContentREF = ref(null);
const scrollToBottom = () => {
  const chatContent = chatContentREF.value;

  if (chatContent) {
    chatContent.scrollTop = chatContent.scrollHeight;
  }
};

watch(
  () => store.state.auth.user,
  async () => {
    if (store.state.auth.user.id) {
      const isExpired = tokenExpired.value < Date.now();
      if (!token.value || isExpired) {
        try {
          await getChatToken();
        } catch (error) {
          toast.error(t("notifications.chat_char_limit"));
          isChatDisabled.value = true;
        }
      }
    }
  },
  { immediate: true },
);

watch(
  () => minimized,
  () => {
    isChatMinimized.value = minimized;
    scrollToBottom();
  },
  { immediate: true },
);
watch(
  () => messages.value,
  async () => {
    await nextTick();
    const chatContent = chatContentREF.value;
    if (chatContent) {
      scrollToBottom();
    }
  },
  { deep: true, immediate: true },
);

onMounted(async () => {
  store.commit("chat/resetButtons");
  setupWebsocket();
});

onUnmounted(() => {
  if (socket.value) {
    socket.value.close();
  }
});
</script>

<template>
  <div class="chat-container" ref="containerREF" :class="isChatMinimized ? 'minimized' : ''">
    <div
      @click="isChatMinimized ? showChat() : null"
      :class="isChatMinimized ? 'cursor-pointer' : ''"
      class="chat-header"
    >
      <img class="chat-icon" src="/src/assets/icons/virtual-assistant-header.svg" alt="Chat Icon" />
      <span class="chat-title">
        {{ $t("chat.ai_legal_assistant") }}
      </span>
      <div>
        <v-btn v-if="isResizeEnabled" @click="toggleFullScreenChat" size="sm" icon variant="plain">
          <v-icon v-show="!isFullScreenChat">mdi-arrow-expand</v-icon>
          <v-icon v-show="isFullScreenChat">mdi-minus</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="content-container" v-show="!isChatMinimized">
      <div class="disclaimer" v-if="isDisclaimerVisible">
        <v-alert closable color="warning" border="left" elevation="0" rounded="0">
          {{ $t("chat.disclaimer") }}
        </v-alert>
      </div>

      <Disclaimer
        v-if="isSecodnDisclaimerVisible"
        :user="user"
        @setVisibility="setSecondDisclaimerVisibility"
      />

      <div class="content" ref="chatContentREF" v-if="!isSecodnDisclaimerVisible">
        <ChatContent :messages="messages" @load="getChatHistory" />
      </div>

      <div class="message-input-container" v-if="!isSecodnDisclaimerVisible">
        <SampleMessages
          @clickMessage="handleSendMessage"
          v-if="messages.length <= 1 && state.includes('editing')"
        />
        <RecommendedButtons @click-button="handleRecommendedButtonClick" />
        <MessageInput
          :chat-disabled="isChatDisabled || isResponseLoading"
          @sendMessage="handleSendMessage"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.minimized {
  height: 45px !important;
}

.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .chat-header {
    display: flex;
    width: 100%;
    background-color: var(--light-grey);
    padding: 10px 10px 10px 48px;

    .chat-title {
      font-family: Archivo, sans-serif;
      font-weight: 600;
      color: var(--blue-bonnet);
      width: 90%;
      margin-left: 15px;
    }

    .hide-button {
      width: 100px;
      margin-right: 9px;
      color: white;
      background-color: var(--blue-bonnet);
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    transition: 0.3s ease-in-out;
    overflow: hidden;
    height: calc(100% - 30px);

    .content {
      flex-grow: 1;
      overflow-y: auto;
      padding: 20px;
    }

    .message-input-container {
      width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
</style>

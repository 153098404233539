import store from "@/store/store";
export default function parseVariableAttributes(variableId) {
  const variable = store.state.editor.availableVariables.find(
    (variable) => variable.id === variableId,
  );

  if (!variable) return;

  const {
    id,
    name,
    type,
    description,
    pattern,
    options,
    example,
    value,
    answer,
    max_options,
    list_type,
  } = variable;

  const newVariable = {
    name,
    description,
    value: value || answer || name,
    id,
    type,
    answer: answer || value,
    example: example || name,
  };

  if (type === "select" && options) {
    newVariable.options = options;
    newVariable.max_options = max_options;
    newVariable.list_type = list_type;
  }

  if (pattern) {
    newVariable.pattern = pattern;
  }

  return newVariable;
}

const prepareContent = (attrs) => {
  const { value, answer, type } = attrs;

  if (type === "select" && answer) {
    const options = typeof answer === "string" ? answer.split(",") : answer || [];
    const listType = attrs.list_type === "number" ? "ol" : "ul";

    return [listType, {}, ...options.map((option) => ["li", {}, option])];
  }

  return `${value}`;
};

export default {
  inline: true,
  group: "inline",
  attrs: {
    name: { default: null },
    description: { default: null },
    value: { default: null },
    id: { default: null },
    answer: { default: null },
    type: { default: null },
    options: { default: null },
    max_options: { default: null },
    list_type: { default: null },
    style: { default: null },
  },
  marks: "_",
  toDOM: (node) => {
    return [
      "variable",
      {
        "data-name": node.attrs.name,
        "data-description": node.attrs.description,
        "data-value": node.attrs.value,
        "data-id": node.attrs.id,
        "data-answer": node.attrs.answer,
        "data-type": node.attrs.type,
        class: `variable-node${node.attrs.type === "select" ? " list-type-" + node.attrs.list_type : ""}`,
        style: node.attrs.style,
      },
      prepareContent(node.attrs),
    ];
  },
  parseDOM: [
    {
      tag: "variable[data-name]",
      getAttrs: (dom) => ({
        name: dom.getAttribute("data-name"),
        description: dom.getAttribute("data-description"),
        value: dom.getAttribute("data-value"),
        id: dom.getAttribute("data-id"),
        answer: dom.getAttribute("data-answer"),
        type: dom.getAttribute("data-type"),
        options: dom.getAttribute("data-options"),
        max_options: dom.getAttribute("data-max-options"),
        list_type: dom.getAttribute("data-list-type"),
      }),
    },
  ],
};

import { format, parseISO } from "date-fns";

export function formatDate(date) {
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export const formatIsoDate = (value) => {
  const parsedDate = parseISO(value);
  return format(parsedDate, "dd-MM-yyyy");
};

import axios from "axios";
import store from "@/store/store";
import {useToast} from "vue-toastification";

const toast = useToast();

const setLatestOpenedDocumentId = (documentId) => {
  localStorage.setItem('latestOpenedDocumentId', documentId);
}

const redirectToDocusign = async () => {
  try {
    const response = await axios.get('/api/docusign/auth-url');

    if (response.status === 200) {
      setTimeout(() => {
        window.location.href = response.data.url;
      });
    }
  } catch (error) {
    console.error('Failed to redirect to DocuSign:', error);
  }
}
export const uploadDocumentToDocusign = async (documentId) => {
  const hasConnectedDocusign = store.state.auth.user.hasConnectedDocusign;

  if (!hasConnectedDocusign) {
    setLatestOpenedDocumentId(documentId);
    return await redirectToDocusign(documentId);
  }

  try {
    const response = await axios.post(`/api/docusign/create-envelope`, {
      document: documentId,
    });

    toast.success('Document uploaded to DocuSign successfully');
    toast.info('Attention! The document signing process is done via DocuSign, which will open in a new tab. Please make sure your browser is not blocking this window.')
    setTimeout(() => {
      window.open(response.data.url, '_blank').focus();
    });
  } catch (error) {
    toast.error('Failed to upload document to DocuSign');
    console.error('Failed to upload document to DocuSign:', error);
  }
}

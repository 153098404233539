<script setup>
import Page from "@/components/Page.vue";
import Chat from "@/components/chat/Chat.vue";
import NewDocumentMobile from "@/views/app/mobile/NewDocumentMobile.vue";
import TemplatesList from "@/components/documents/TemplatesList.vue";
import LatestDocumentsList from "@/components/documents/LatestDocumentsList.vue";
import PopularTemplatesList from "@/components/documents/PopularTemplatesList.vue";
import SubscriptionOrSinglePaymentRequiredDialog from "@/components/dialogs/SubscriptionOrSinglePaymentRequiredDialog.vue";
import TermsAcceptRequiredDialog from "@/components/dialogs/TermsAcceptRequiredDialog.vue";

import axios from "axios";
import router from "@/router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useIsMobile } from "@/utils/checkMobile";

const { t } = useI18n();
const store = useStore();
const selectedTemplateId = ref();
const isMobile = useIsMobile();

const isChatOpen = ref(true);
const messages = ref([
  {
    id: crypto.randomUUID(),
    source: "bot",
    message: t("chat.new_document_welcome_message"),
  },
]);

const isPaymentDialogOpen = ref(false);

const handleTemplate = async (templateId) => {
  try {
    const response = await axios.post("api/documents", {
      template_id: templateId,
    });

    store.commit("editor/updateDocumentId", response.data.data.id);
    store.commit("chat/updateSessionId", response.data.data.id);

    await router.push({
      name: "new-document-creator",
      params: { uuid: response.data.data.id },
    });
  } catch (error) {
    if (error.response.status === 402) {
      selectedTemplateId.value = templateId;
      isPaymentDialogOpen.value = true;
    }
  }
};

onMounted(() => {
  //create new document from template uuid in route
  const uuidInRoute = router.currentRoute.value.params.templateId;
  if (uuidInRoute) {
    handleTemplate(uuidInRoute);
  }
});
</script>

<template>
  <TermsAcceptRequiredDialog />

  <NewDocumentMobile v-if="isMobile" />
  <div v-else>
    <Page :title="t('documents.new_document')" :with-back-button="false">
      <div class="new-document-container">
        <div class="documents-container">
          <TemplatesList @handle-template="handleTemplate" />
          <div class="spacer mt-5 mb-5"></div>
          <LatestDocumentsList :items="3" />
          <div class="spacer mt-5 mb-5"></div>
          <PopularTemplatesList @handle-template="handleTemplate" :items="3" />
        </div>
        <div class="documents-chat-container">
          <div class="px-5 py-5" v-if="isChatOpen">
            <div class="minimize-button">
              <v-btn size="sm" icon @click="isChatOpen = !isChatOpen" variant="plain">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </div>
            <h3 class="mb-2">{{ t("chat.do_you_need_help") }}</h3>
            <p>{{ t("chat.ask_legal_assistant") }}</p>
          </div>
          <Chat
            @show-chat="isChatOpen = true"
            :initial-messages="messages"
            :currentDocument="null"
            :minimized="!isChatOpen"
            class="pb-4"
          />
        </div>
      </div>
    </Page>
    <SubscriptionOrSinglePaymentRequiredDialog
      v-if="selectedTemplateId"
      :template-id="selectedTemplateId"
      :is-dialog-open="isPaymentDialogOpen"
      @close-dialog="isPaymentDialogOpen = false"
    />
  </div>
</template>

<style scoped lang="scss">
.new-document-container {
  display: flex;
  margin-top: 20px;
  height: 100%;
  position: relative;

  .chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (max-height: 600px) {
      height: 300px;
    }

    @media (min-height: 600px) and (max-height: 900px) {
      height: 400px;
    }

    @media (min-height: 900px) {
      height: 600px;
    }
  }

  .documents-chat-container {
    display: flex;
    flex-direction: column;
    width: 30%;
    border: 1px solid #e1e1e1;
    position: fixed;
    bottom: 0;
    right: 0;

    @media (max-width: 1280px) and (max-height: 600px) {
      bottom: auto;
      top: 120px;
      background-color: white;
    }
  }

  .minimize-button {
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
  }

  :deep(.content) {
    height: 600px !important;
  }

  .documents-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-right: 7%;
  }
}
</style>

import store from "@/store/store";
import { useToast } from "vue-toastification";

const toast = useToast();

window.addEventListener("DOMContentLoaded", function () {
  const channel = window.Echo.private(`users.${store.state.auth.user.id}`);

  const events = [
    "NewTemplateAddedEvent",
    "NewTemplatesAddedEvent",
    "TemplateUpdatedEvent",
    "SharedDocumentOpenedEvent",
    "DocumentApprovedEvent",
    "DocumentCommentedEvent",
    "DocumentSignedEvent",
  ];

  channel
    .subscribed(function () {
      const handleNotification = (notification) => {
        toast.success(notification.message);

        const unreadNotificationsNumber = store.state.shared.unreadNotifications || 0;
        store.commit("shared/updateUnreadNotifications", unreadNotificationsNumber + 1);
      };

      for (const event of events) {
        channel.listen(event, (notification) => {
          handleNotification(notification);
        });
      }
    })
    .error(function (error) {
      console.error("Error ", error);
    });
});

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";

const router = useRouter();
const store = useStore();

onMounted(async () => {
  const templateId = router.currentRoute.value.params.templateId;

  store.commit("shared/updateIsLoading", true);

  try {
    const response = await axios.post("api/documents", {
      template_id: templateId,
    });

    store.commit("editor/updateDocumentId", response.data.data.id);
    store.commit("chat/updateSessionId", response.data.data.id);

    await router.push({
      name: "new-document-creator",
      params: { uuid: response.data.data.id },
    });
  } catch (error) {
    console.error(error.message);
    router.push("/new-document");
  } finally {
    store.commit("shared/updateIsLoading", false);
  }
});
</script>
<template></template>
<style scoped lang="scss"></style>
